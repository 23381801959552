import React from 'react';
import { find, capitalize, kebabCase, toLower, replace } from 'lodash';

export const formatPrice = (price) => {
    return price !== null && price !== undefined
        ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD'
          }).format(price / 100)
        : '$-.--';
};

export const getProductPrice = (
    product,
    productType,
    priceGroup,
    format = true
) => {
    if (!product?.productTypes.nodes.length) {
        return format
            ? formatPrice(product.productInformation.price)
            : parseFloat(product.productInformation.price);
    }

    switch (productType) {
        case 'subscription':
            const stripePriceSubscription = find(product.stripePrices, {
                priceGroup,
                interval_count: 1,
                interval: 'month'
            });
            if (stripePriceSubscription) {
                const { unitAmount, interval } = stripePriceSubscription;
                if (format) {
                    const amount = unitAmount
                        ? formatPrice(unitAmount)
                        : '$-.--';
                    return (
                        <>
                            {amount}
                            <span>Per {capitalize(interval)}</span>
                        </>
                    );
                }
                return unitAmount ? unitAmount : 0;
            }
            return '$-.--';
        case 'physical':
        case 'digital':
            if (product.stripePrices.length) {
                const stripePrice = find(product.stripePrices, {
                    priceGroup
                });
                if (stripePrice) {
                    const { unitAmount: physicalUnitAmount } = stripePrice;
                    return format
                        ? formatPrice(physicalUnitAmount)
                        : physicalUnitAmount;
                }
            }
            if (product.productInformation.price) {
                return format
                    ? formatPrice(product.productInformation.price * 100)
                    : parseFloat(product.productInformation.price) * 100;
            }
            return '$-.--';
        case 'gift-card':
            return format
                ? formatPrice(product.productInformation.price * 100)
                : parseFloat(product.productInformation.price) * 100;
        default:
            return '$-.--';
    }
};

const formatProductDisplayNameUrl = (displayName) => {
    return kebabCase(toLower(replace(displayName, /[^a-zA-Z0-9 -]/g, '')));
};

export const getProductUrl = (product) => {
    let url = product.uri;
    if (product.displayName) {
        url = `/product/${formatProductDisplayNameUrl(product.displayName)}`;
    }
    return url;
};
